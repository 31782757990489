




























































































import AssignRulesListItem from './AssignRulesListItem/AssignRulesListItem.vue';
import {
  Component, Prop, Vue, Watch
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
  ScenariosAction,
  ScenariosActionType,
  ScenariosState
} from '@/store/modules/scenarios/types';
import Draggable from 'vuedraggable';
import { Models } from '@mtap-smartcity/lib-api';
import { GraphsAction, GraphsActionType } from '@/store/modules/graphs/types';

const scenarios = namespace('scenarios');
const graphs = namespace('graphs');

@Component({
  components: {
    AssignRulesListItem,
    Draggable,
  }
})
export default class AssignRulesList extends Vue {
  @Prop({ type: Boolean, default: false }) readonly isDefaultScenario!: boolean

  @scenarios.State
  selectedScenarioUuid!: ScenariosState['selectedScenarioUuid'];

  @scenarios.State
  scenarioElements!: ScenariosState['scenarioElements'];

  @scenarios.Action(ScenariosAction.FetchScenarioElementsByScenario)
  fetchScenarioElementsByScenario!: ScenariosActionType['FETCH_SCENARIO_ELEMENTS_BY_SCENARIO'];

  @scenarios.Action(ScenariosAction.UpdateScenarioElementsPriorities)
  updateScenarioElementsPriorities!: ScenariosActionType['UPDATE_SCENARIO_ELEMENTS_PRIORITIES']

  @scenarios.Action(ScenariosAction.ClearStateScenarioElements)
  clearStateScenarioElements!: ScenariosActionType['CLEAR_STATE_SCENARIO_ELEMENTS'];

  @scenarios.Action(ScenariosAction.DeleteScenarioElement)
  deleteScenarioElement!: ScenariosActionType['DELETE_SCENARIO_ELEMENT']

  @graphs.Action(GraphsAction.DeleteGraph)
  deleteGraph!: GraphsActionType['DELETE_GRAPH'];

  dialog = false;

  loadingData = false;

  draggingIsEnabled = false;

  savedElements: ScenariosState['scenarioElements'] = [];

  fixedElements: ScenariosState['scenarioElements'] = [];

  listTransitionEnabled = true;

  deleteData = null as { scenarioElementUuid: string, graphUuid: string } | null;

  get orderNotChanged() {
    return JSON.stringify({ elements: this.savedElements }) === JSON.stringify({ elements: this.draggableScenarioElements });
  }

  get draggableScenarioElements() {
    return this.fixedElements;
  }

  set draggableScenarioElements(value) {
    this.fixedElements = value;
  }

  editOrder() {
    this.savedElements = [...this.scenarioElements];
    this.draggingIsEnabled = true;
  }

  cancelOrderChange() {
    this.$store.commit(`scenarios/${ScenariosAction.FetchScenarioElements}Handler`, this.savedElements);
    this.draggingIsEnabled = false;
  }

  async submitOrderChange() {
    if (this.orderNotChanged) return;
    const priorities: Models.ScenarioElements.Priority[] = [];
    this.fixedElements.forEach((sE: Models.ScenarioElements.Model, index) => {
      priorities.push({
        uuid: sE.uuid ?? '',
        priority: this.fixedElements.length - index,
      });
    });
    if (priorities.length === this.fixedElements.length) {
      await this.updateScenarioElementsPriorities(priorities)
        .finally(async () => {
          await this.fetchScenarioElementsByScenario({ uuid: this.selectedScenarioUuid as string });
          this.savedElements = this.scenarioElements;
          this.draggingIsEnabled = false;
        });
    }
  }

  startDrag() {
    this.listTransitionEnabled = false;
  }

  stopDrag() {
    this.listTransitionEnabled = true;
  }

  changeOrder(emittedOrderInfo: { oldPlace: number, newPlace: number }) {
    const elementSwitchingPlace = this.draggableScenarioElements[emittedOrderInfo.oldPlace];
    this.draggableScenarioElements.splice(emittedOrderInfo.oldPlace, 1);
    this.draggableScenarioElements.splice(emittedOrderInfo.newPlace, 0, elementSwitchingPlace);
  }

  confirmRuleDelete({ scenarioElementUuid, graphUuid }) {
    this.dialog = true;
    this.deleteData = { scenarioElementUuid, graphUuid };
  }

  async deleteModal() {
    if (this.deleteData) {
      await this.deleteScenarioElement(this.deleteData.scenarioElementUuid);
      await this.deleteGraph(this.deleteData.graphUuid);
    }
    this.deleteData = null;
    this.dialog = false;
  }

  closeModal() {
    this.dialog = false;
    this.deleteData = null;
  }

  async loadScenarioElements(uuid: string) {
    this.draggingIsEnabled = false;
    if (uuid) {
      this.clearStateScenarioElements();
      this.loadingData = true;
      await this.fetchScenarioElementsByScenario({ uuid });
      this.loadingData = false;
      this.savedElements = this.scenarioElements;
    }
  }

  @Watch('selectedScenarioUuid')
  onSelectedScenarioUuidChange(uuid: string) {
    this.loadScenarioElements(uuid);
  }

  @Watch('scenarioElements')
  onScenarioElementsChange() {
    this.fixedElements = [...this.scenarioElements];
  }
}
