




































































import ScenarioRulePreviewChart from './ScenarioRulePreviewChart/ScenarioRulePreviewChart.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { rrulestr } from 'rrule';
import { Models } from '@mtap-smartcity/lib-api';
import { ScenariosAction, ScenariosActionType } from '@/store/modules/scenarios/types';
import {
  GraphsAction, GraphsActionType, GraphsGetter, GraphsGetterType
} from '@/store/modules/graphs/types';

const scenarios = namespace('scenarios');
const graphs = namespace('graphs');

const ScenarioRuleActions: any = () => ({
  component: import('./ScenarioRuleActions/ScenarioRuleActions.vue')
});

@Component({
  components: {
    ScenarioRulePreviewChart,
    ScenarioRuleActions
  }
})
export default class AssignRulesListItem extends Vue {
  @Prop(Object) readonly scenarioElement!: Models.ScenarioElements.Model

  @Prop({ type: Boolean, default: false }) readonly isDefaultScenario!: boolean

  @Prop(Number) readonly index!: number

  @Prop(Boolean) readonly draggingIsEnabled!: boolean

  @Prop(Number) readonly listLength!: number

  @scenarios.Action(ScenariosAction.DeleteScenarioElement)
  deleteScenarioElement!: ScenariosActionType['DELETE_SCENARIO_ELEMENT']

  @scenarios.Action(ScenariosAction.SetSelectedScenarioElementID)
  setSelectedScenarioElementID!: ScenariosActionType['SET_SELECTED_SCENARIO_ELEMENT_ID']

  @graphs.Action(GraphsAction.DeleteGraph)
  deleteGraph!: GraphsActionType['DELETE_GRAPH'];

  @graphs.Getter(GraphsGetter.GetGraph)
  getGraph!: GraphsGetterType['GET_GRAPH'];

  newIndex: number = this.index

  get recurrence() {
    return rrulestr(this.scenarioElement.rrule).options;
  }

  get graph() {
    if (!this.scenarioElement.graph_id) return {};
    return this.getGraph(this.scenarioElement.graph_id);
  }

  get isLastElement() {
    return this.listLength === 1;
  }

  edit(id: number) {
    this.setSelectedScenarioElementID(id);
    this.$emit('show-modal');
  }

  deleteElement(element: { scenarioElementUuid: string, graphUuid: string }) {
    this.$emit('modalAction', {
      scenarioElementUuid: element.scenarioElementUuid,
      graphUuid: element.graphUuid
    });
  }

  changeOrder(order: { oldPlace: number, newPlace: number }) {
    this.$emit('changeOrder', { oldPlace: order.oldPlace, newPlace: order.newPlace });
  }
}
