






















import { Models } from '@mtap-smartcity/lib-api';
import {
  Component, Vue, Prop, PropSync, Watch
} from 'vue-property-decorator';
import ScenarioElementChart from './ScenarioElementChart/ScenarioElementChart.vue';
import ScenarioElementChartRule from './ScenarioElementChartRule.vue';
import { bisector } from 'd3';

@Component({
  components: {
    ScenarioElementChart,
    ScenarioElementChartRule,
  }
})
export default class ScenarioElementModalGraph extends Vue {
  @PropSync('changes') changesSynced!: Models.Graphs.Model['changes']

  @Prop({ type: Object, required: true }) sunriseSunsetRanges!: Models.Scenarios.SunriseSunsetRange

  graphRules: Models.Graphs.Rule[] = [];

  graphRulesHandlers = {
    addNewRule: (rule: Models.Graphs.Rule) => this.addNewRule(rule),
    modifyChanges: (ruleData: Models.Graphs.Rule) => this.modifyChanges(ruleData),
    removeRule: (offset: number) => this.removeRule(offset)
  }

  freshSunriseSunsetRange = true;

  @Watch('sunriseSunsetRanges')
  onSunriseSunsetRangeChange() {
    this.freshSunriseSunsetRange = false;
    this.$nextTick(() => {
      // Add the component back in
      this.freshSunriseSunsetRange = true;
    });
  }

  rulesFromArr(dutyArr: number[]) {
    const rules: Models.Graphs.Rule[] = [];
    let currDuty = dutyArr[0];
    let offset = 0;
    rules.push({ offset, duty: currDuty });
    for (let i = 1; i < dutyArr.length; i += 1) {
      const duty = dutyArr[i];
      if (duty !== currDuty) {
        currDuty = duty;
        offset = i;
        rules.push({ offset, duty });
      }
    }
    return rules;
  }

  addNewRule({ duty, offset }: Models.Graphs.Rule) {
    const bisect = bisector((d: any) => d.offset).left;
    const idx = bisect(this.graphRules, offset);
    this.graphRules.splice(idx, 0, { duty, offset });
    this.modifyChanges({ duty, offset });
  }

  removeRule(offset: number) {
    const ruleIndex = this.graphRules.findIndex((r) => r.offset === offset);
    if (ruleIndex !== 0) {
      const { duty } = this.graphRules[ruleIndex - 1];
      this.modifyChanges({ duty, offset });
      this.graphRules.splice(ruleIndex, 1);
    }
  }

  modifyChanges({ duty, offset }: Models.Graphs.Rule) {
    const ruleIndex = this.graphRules.findIndex((r) => r.offset === offset);
    if (ruleIndex !== -1) {
      this.graphRules[ruleIndex].duty = duty;
      const changesIndexStart = offset;
      const changesIndexEnd = this.graphRules[ruleIndex + 1]?.offset || this.changesSynced.length;
      for (let i = changesIndexStart; i < changesIndexEnd; i += 1) {
        this.changesSynced[i] = duty;
      }
    }
  }

  created() {
    this.graphRules = this.rulesFromArr(this.changesSynced);
  }
}
