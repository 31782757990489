






















































import { Models } from '@mtap-smartcity/lib-api';
import { Component, Vue, Prop } from 'vue-property-decorator';
import timeFromIndex from './ScenarioElementChart/features/date-format';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';

@Component({
  components: {
    VueTimepicker
  }
})
export default class ScenarioElementChartRule extends Vue {
  @Prop({ required: true, type: Object }) rule!: Models.Graphs.Rule

  minutesInterval = 15

  isError = false;

  dutyInputKey = 0

  rules = {
    dutyBounds: (value) => (value >= 0 && value <= 100) || 'Podaj wartość od liczbową od 0-100.'
  }

  get duty() {
    return this.rule.duty;
  }

  set duty(duty) {
    this.$emit('modifyChanges', { ...this.rule, duty });
  }

  get since() {
    return timeFromIndex(this.rule.offset);
  }

  changeDuty(event) {
    if (this.isError) {
      this.dutyInputKey += 1;
      this.isError = false;
    } else {
      this.duty = event.target.value;
    }
  }

  localeTimeStrToOffset(timeStr: string) {
    const [hours, minutes] = timeStr.split(':').map((s) => parseInt(s, 10));
    return (hours * 60 + minutes) / this.minutesInterval;
  }

  removeRule() {
    this.$emit('removeRule', this.rule.offset);
  }
}
