













































































































import {
  Component, Vue, Prop, Watch
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Models } from '@mtap-smartcity/lib-api';
import { ScenariosAction, ScenariosActionType, ScenariosState } from '@/store/modules/scenarios/types';
import { AppState } from '@/store/modules/app/types';
import { focusOnNewItemNameInput } from '@/utils/focus_on_input';

const scenarios = namespace('scenarios');
const app = namespace('app');

@Component
export default class ScenariosListItem extends Vue {
  @Prop(Object) readonly scenario!: Models.Scenarios.Model;

  @Prop(Boolean) readonly userEditingScenario!: boolean;

  @Prop(Boolean) readonly permissionCheckEdit!: boolean;

  @Prop(Boolean) readonly permissionCheckDelete!: boolean;

  @Prop(Array) readonly forbiddenNames!: Array<string>;

  elementKey = 1;

  scenarioData: Models.Scenarios.Model = { ...this.scenario };

  editState = false;

  showNameTooltip: boolean = false;

  itemName = '';

  isDefaultData: boolean = this.scenario.is_default;

  @app.State
  userActionStatus!: AppState['userActionStatus'];

  @scenarios.State
  selectedScenarioID!: ScenariosState['selectedScenarioID'];

  @scenarios.Action(ScenariosAction.CreateScenario)
  createScenario!: ScenariosActionType['CREATE_SCENARIO'];

  @scenarios.Action(ScenariosAction.UpdateScenario)
  updateScenario!: ScenariosActionType['UPDATE_SCENARIO'];

  @scenarios.Action(ScenariosAction.SetScenarioAsDefault)
  setScenarioAsDefault!: ScenariosActionType['SET_SCENARIO_AS_DEFAULT'];

  @scenarios.Action(ScenariosAction.RemoveScenarioPlaceholder)
  removeScenarioPlaceholder!: ScenariosActionType['REMOVE_SCENARIO_PLACEHOLDER'];

  get isSelected() {
    return this.selectedScenarioID === this.scenario.id;
  }

  get isDefaultSetToTrue() {
    return this.isDefaultData && this.isDefaultData !== this.scenario.is_default;
  }

  @Watch('editState')
  onEditStateChange(editState) {
    this.$emit('update:user-editing-scenario', editState);
  }

  editScenario() {
    this.scenarioData = { ...this.scenario };
    this.editState = true;
    this.itemName = this.scenarioData.name;
    focusOnNewItemNameInput();
  }

  toggleScenarioDefaultProp() {
    this.isDefaultData = !this.isDefaultData;
  }

  discard(): void {
    // TODO
    this.scenarioData = { ...this.scenario };
    this.isDefaultData = this.scenario.is_default;
    this.editState = false;
    this.elementKey += 1;
    this.showNameTooltip = false;
    if (!this.scenario.id) {
      if (this.editState !== this.userEditingScenario) {
        this.$emit('update:user-editing-scenario', this.editState);
      }
      this.removeScenarioPlaceholder();
    }
  }

  accept(): void {
    const forbiddenNamesOnUpdate = this.forbiddenNames.filter((fN) => fN !== this.itemName);
    if (!this.scenarioData.name || forbiddenNamesOnUpdate.includes(this.scenarioData.name)) {
      focusOnNewItemNameInput();
      this.showNameTooltip = true;
      return;
    }
    if (!this.scenario.id) {
      this.acceptCreateScenario();
    } else if (this.scenarioData.name !== this.scenario.name) {
      this.acceptUpdateScenario();
    } else if (this.isDefaultSetToTrue) this.acceptSetScenarioAsDefault();
    this.editState = false;
  }

  acceptCreateScenario() {
    this.createScenario(this.scenarioData).catch(() => {
      this.elementKey += 1;
    }).finally(() => {
      this.removeScenarioPlaceholder();
      this.editState = false;
      this.showNameTooltip = false;
    });
  }

  acceptUpdateScenario() {
    this.updateScenario(this.scenarioData).catch(() => {
      this.elementKey += 1;
    }).finally(() => {
      if (this.isDefaultSetToTrue) this.acceptSetScenarioAsDefault();
      else {
        this.editState = false;
        this.showNameTooltip = false;
      }
    });
  }

  acceptSetScenarioAsDefault() {
    this.setScenarioAsDefault(this.scenarioData).catch(() => {
      this.elementKey += 1;
    }).finally(() => {
      this.editState = false;
      this.showNameTooltip = false;
    });
  }

  mounted() {
    if (!this.scenario.id) this.editState = true;
  }
}
